
import PrismicData from '~/assets/js/prismic-controller.js'
import ProductBuy from '~/components/product/ProductBuy.vue'
import SlicesBlock from '~/components/SlicesBlock.vue'
import productPagesUids from '~/plugins/product-pages-uids.js'
export default {
  name: 'Page',
  components: {
    ProductBuy, SlicesBlock
  },
  async asyncData({ $prismic, app, store, params, error, req }) {
    const lang = app.i18n.locales.filter(i => i.iso.includes(app.i18n.locale))[0].iso
    try {
      const productsUid = productPagesUids
      let isProductPage = false
      let results = false

      if (productsUid.includes(params.uid)) {
        isProductPage = true
        results = await $prismic.api.query($prismic.predicates.at('my.product_page.uid', params.uid), { lang })
      } else {
        results = await $prismic.api.query($prismic.predicates.at('my.page.uid', params.uid), { lang })
      }

      const page = results.results[0]

      /* Alt Lang */
      function getAltLangNav(altLanguages) {
        return altLanguages.reduce((a, item) => {
          const lang = item.lang.substr(0, 2)
            a[lang] = {uid: item.uid}
            return a
        },{})
      }
      if (page.alternate_languages.length) {
        const altLang = getAltLangNav(page.alternate_languages)
        store.dispatch('i18n/setRouteParams', altLang)
      }

      const contentTypes = []
      if (page.data.body?.length){
        page.data.body.forEach((slice, index) => {
          if (slice.slice_type === 'content_type_list') { contentTypes.push({ slice: slice.primary, index }) }
        })
        if (contentTypes.length) {
          const contentTypesPromises = contentTypes.map(async(contentType) => {
            try {
              const queryParams = [$prismic.predicates.at('document.type', contentType.slice.type.toLowerCase())]
              if (contentType.slice.tag?.length) {
                queryParams.push($prismic.predicates.at('document.tags', [contentType.slice.tag]))
              }
              const { results } = await $prismic.api.query(queryParams, { lang })
              page.data.body[contentType.index].items = results
            } catch (e) {
              console.error(e)
              error({ statusCode: 404, message: 'Can not fetch Content Type' })
            }
          })
          await Promise.all(contentTypesPromises)
        }
      }

      store.dispatch('setActivePage', page.data)

      return {
        document: page.data,
        slices: page.data.body || [],
        documentUid: page.uid,
        documentLang: page.lang.substr(0, 2),
        isProductPage
      }
    } catch (e) {
      error({ message: JSON.stringify(e), statusCode: 404 })
    }
  },
  computed: {
    isIndex(){
      return this.$route.name.includes('index')
    },
    isHeroWide(){
      return this.document?.hero_class?.includes('hero--wide') || this.document?.hero_class?.includes('hero--full') || this.document?.hero_class?.includes('hero--home')
    },
    isPageSpecial() {
      return this.document?.container_class === 'special'
    },
    isShelfy(){
      return this.document?.name?.includes('Shelfy')
    },
  },
  head() {
    return PrismicData.seo(this.$nuxtI18nHead({ addSeoAttributes: true }), this.document, this.documentUid, this.documentLang)
  }
}
