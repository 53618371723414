
export default {
  name: 'ProductVariantSelector',
  props: ['variants','variants_title'],
  data() {
    return {
      selectedProduct: false,
      mappedVariants: [],
      variantType: null
    }
  },
  computed: {
    isLoading(){
      return this.$store.getters.isLoading
    },
    products(){
      return this.$store.getters.products
    },
    queryVariant(){
      return this.$route.query?.variant
    },
    isNatedeSmart(){
      return this.$route.params.uid === 'natede-smart'
    },
    isNatedeBasic(){
      return this.$route.params.uid === 'natede-basic'
    },
    isShelfy(){
      return this.$route.params.uid === 'shelfy'
    }
  },
  watch: {
    async products(){
      await this.mapVariants()
    },
    selectedProduct(oldItem, newItem){
      if (JSON.stringify(oldItem) !== JSON.stringify(newItem)) {
        this.trackViewItem(this.selectedProduct)
      }
    }
  },
  mounted() {
    this.mapVariants()
  },
  methods: {
    mapVariants(){
      this.mappedVariants = this.variants.map((v, index) => {
        const ccProduct = this.products.find(p => p.sku_code === v.v_sku)
        const optionName = v.v_attribute?.substring(v.v_attribute.indexOf('--')+2)
        
        this.variantType = v.v_attribute
        if (v.v_attribute && v.v_attribute.indexOf('--') > 0) {
          this.variantType = v.v_attribute?.substring(0, v.v_attribute.indexOf('--'))
        }
        
        // Quantity attribute + price tiers
        let priceTiersAttributes = {}
        if (this.variantType === 'quantity' && ccProduct?.price_tiers) {
          const quantity = this.variantType === 'quantity' ? parseInt(optionName) : 1
          priceTiersAttributes = {
            quantity: quantity,
            price: ccProduct.price_tiers[index].price_amount_float,
            tier_compared_at_price: ccProduct.compare_at_price * quantity,
            tier_price: ccProduct.price_tiers[index].price_amount_float * quantity,
            discount_percentage: Math.round(100 - ccProduct.price_tiers[index].price_amount_float / ccProduct.compare_at_price * 100),
            discount_amount: this.$options.filters.price(ccProduct.compare_at_price * quantity - ccProduct.price_tiers[index].price_amount_float * quantity, ccProduct.currency_code),
            discount_amount_int: ccProduct.compare_at_price * quantity - ccProduct.price_tiers[index].price_amount_float * quantity
          }
        } else {
          v.discount_percentage = this.discountPercentage(ccProduct)
          const amount = this.discountAmount(ccProduct)
          v.discount_amount = this.$options.filters.price(amount, ccProduct?.currency_code) 
          v.discount_amount_int = amount
        }

        return { 
          ...ccProduct, 
          ...v, 
          v_option_name: optionName,
          ...priceTiersAttributes,
        }
      })


      // if (this.isShelfy && this.$store.state.country.code === 'IT') {
      //   const academiaBundle = this.products.find(p => p.sku_code === 'MK-GCACT00')
      //   const shelfyData = this.mappedVariants[0]
      //   if (academiaBundle) {
      //     this.mappedVariants.push({
      //       ...academiaBundle, 
      //       v_attribute:"quantity--bundle",
      //       v_group:"quantity",
      //       v_name: "Shelfy + Acadèmia.tv",
      //       description: `Include l’abbonamento annuale a tutti i corsi di cucina di Acadèmia.tv. <a href="https://landing.vitesy.com/offerta-shelfy-academiatv?utm_source=vitesy.com&utm_medium=website&utm_content=variant" target="_blank" class="text--academiatv">Scopri di più</a>`,
      //       promo: `Offerta 2 in 1`,
      //       sku_code: shelfyData.sku_code,
      //       name: shelfyData.name,
      //       image_url: shelfyData.image_url,
      //       price: shelfyData.tier_price + academiaBundle.price,
      //       tier_price: shelfyData.tier_price + academiaBundle.price,
      //       tier_compared_at_price: shelfyData.compare_at_price + academiaBundle.compare_at_price,
      //       optional_service: academiaBundle
      //     })
      //   }
      // }

      if (this.isNatedeBasic && this.$store.state.country.code === 'US') {
        const natedeAzalea = this.products.find(p => p.sku_code === 'NT02AA03')
        if (natedeAzalea) {
          this.mappedVariants.push({
            ...natedeAzalea, 
            v_attribute:"quantity--bundle",
            v_group:"quantity",
            v_name: "Natede Azalea",
            description: `Purification. The same as Natede but colorful.`,
            promo: `Limited Edition`,
          })
        }
      }


      let variant = this.mappedVariants[0]
      if (this.queryVariant) {
        variant = this.mappedVariants.find(v => v.sku_code === this.queryVariant)
      }
      if (variant) {
        this.selectProduct(variant, true)
      }
    },
    discountPercentage(product){
      if (product && product.price) {
        return Math.round(100 - product.price / product.compare_at_price * 100)
      } else {
        return 0
      }
    },
    discountAmount(product){
      if (product && product.price) {
        const amount = product.compare_at_price - product.price
        return amount
      } else {
        return false
      }
    },
    selectProduct(variant, autoSelect) {
      if (variant && (variant.sku_code || variant.bundle_code)) {
        if (this.isNatedeBasic && variant.sku_code === "NT01AA02"){
          this.$router.push(this.localePath({path:'natede-smart'}))
        } else if (this.isNatedeSmart && variant.sku_code === "NT02AA00"){
          this.$router.push(this.localePath({path:'natede-basic'}))
        } else {
          this.selectedProduct = variant
          this.$emit('setProduct', {...variant, autoSelect: autoSelect})
          this.selectCount++
        }
      }
    },
    trackViewItem(product) {
        setTimeout(()=>{
          if (product) {
            const eventAttributes = {
              ecommerce: {
                items: [{
                  item_id: product.sku_code || product.bundle_code,
                  item_name: product.name,
                  price: product.compare_at_price,
                  item_brand: 'Vitesy',
                  item_category: product.category,
                  discount:  product.compare_at_price - product.price,
                  quantity: product.quantity
                }]
              }
            }
            this.$gtm.push({ event: 'view_item', ...eventAttributes })
          }
        }, 1000)
      }
  }
}
