import { render, staticRenderFns } from "./Keypoints.vue?vue&type=template&id=7c464742&scoped=true&lang=pug"
import script from "./Keypoints.vue?vue&type=script&lang=js"
export * from "./Keypoints.vue?vue&type=script&lang=js"
import style0 from "./Keypoints.vue?vue&type=style&index=0&id=7c464742&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c464742",
  null
  
)

export default component.exports